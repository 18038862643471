<template>
  <div>
    <yb-top-nav :active="false" />
    <NuxtPage />
  </div>
</template>

<script>
import YbTopNav from "@/layouts/_topnav.vue"

export default {
  name: "TrialExpired",
  components: { YbTopNav }
}
</script>

<style scoped>

</style>
